








































































































































import { Vue, Component } from 'vue-property-decorator'
import HeaderBox from '@/components/HeaderBox.vue'
import ImagePreview from '@/components/ImagePreview.vue'

@Component({
  components: {
    HeaderBox,
    ImagePreview
  }
})
export default class OrderDetail extends Vue {
  showPreviewWindow: boolean = false
  currentIndex: number = 0
  imageList = []
  orderDetail: any = {}
  order_info: any = {}
  car_info: any = {}
  dealer_info: any	= {}
  store_info = {}
  center_car_id = null

  created () {
    const { center_car_id, auction_id } = this.$route.query
    this.center_car_id = center_car_id;
    this.getOrderDetail(center_car_id, auction_id);
  }

  async getOrderDetail (center_car_id, auction_id) {
    const { code, data } = await this.$rest.carManage.getOrderDetail({
      center_car_id,
      auction_id
    });
    if (code !== 0) return;
    this.orderDetail = data;
    this.order_info = data.order_info;
    this.car_info = data.car_info;
    this.dealer_info = data.dealer_info;
    this.store_info = data.store_info;
  }

  handleLookBid () {
    const { href } = this.$router.resolve({
      path: '/carManage/stock/bidInfo',
      query: {
        center_car_id: this.center_car_id,
        auction_id: this.orderDetail.auction_id
      }
    });
    window.open(href, '_blank');
  }

  handleLookPhoto () {
    this.showPreviewWindow = true;
    this.imageList = []
    this.order_info.images.forEach(item => {
      this.imageList.push({
        src: item.src,
        name: '凭证照片'
      })
    })
  }

  handleGo (path, params) {
    const { href } = this.$router.resolve({ path, query: params });
    window.open(href, '_blank');
  }

  get orderStatus () {
    const type = this.order_info ? this.order_info.order_status : 0
    if (type === 1) {
      return { status :'已拍出', desc: '待经销商处理' }
    } else if (type === 2) {
      return { status :'已收款', desc: '' }
    } else if (type === 3 || type === 4 || type === 5) {
      return {status: '已拍出', desc: '交易关闭'}
    }
    return {}
  }
}
